import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import XLSX from 'sheetjs-style';
// import ProgressBar from '@ramonak/react-progress-bar';
import _ from 'lodash';
import Button from '@ttd/maui/lib/components/core/Button';
// import primaryColorNumbers from '@ttd/maui/lib/util/Colors';
import SteppedProgressBar from '@ttd/maui/lib/components/display/SteppedProgressBar';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import DownloadReport from './DownloadReport';

import { exportPackageCampaigns } from '../BulkEdit/PackageExport';

import { runBulkUpdate } from './ProcessUpdates/index';

import './BulkEdit.scss';
import {
  HIDE_STEP,
  SET_EXPORT_BASE,
  SET_UPDATE_BASE,
  UPDATE_INDEX_STEP
} from '../../reducers/stepProgressReducer';
import {
  cleanData,
  IExcelDataArray,
  templateTabs,
  // verifyHeader,
  verifyTemplateHeader
} from '../../utils/processExcelFile';

interface progressStep {
  stepKey: string;
  stepName: string;
  percentComplete: number;
  showPercentDetails: true;
  isComplete: boolean;
  isCompleteStep?: boolean;
}

const BulkEdit = () => {
  const history = useHistory();
  const [completed, setCompleted] = useState<number>(0);
  const [start, setStart] = useState(false);
  const [fileData, setFileData] = useState([] as IExcelDataArray);
  const [fileName, setFileName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [statusReport, setStatusReport] = useState([] as any);
  const progressStep = useSelector((state: RootState) => {
    // console.log(JSON.stringify(state.stepProgress));
    return state.stepProgress;
  });
  const homePageUrl = useSelector(
    (state: RootState) => state.package.homePageUrl
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!start) {
      return;
    }

    if (completed >= 100) {
      setTimeout(() => {
        setStart(false);
      }, 1000);
      return;
    }
  }, [completed, start]);

  const handleUpload = () => {
    runBulkUpdate(fileData, setStart, handleProgress);
  };

  const handleChangeFile = (event) => {
    if (event.target.files && event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    setFileName(file.name);

    // Check only excel accepted
    if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls')) {
      setErrorMessage('Please upload only an Excel file.');
      event.target.value = null;
      return;
    }

    //check file over 5Mb
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 5) {
      setErrorMessage('File size exceeds 5 MB');
      event.target.value = null;
      return;
    }

    // reset state
    setErrorMessage('');
    setCompleted(0);
    setStatusReport([]);

    const reader = new FileReader();
    reader.onload = handleFileLoad;
    reader.readAsBinaryString(file);
  };

  const handleFileLoad = (e) => {
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: 'binary' });
    var date1904 = !!((wb.Workbook || {}).WBProps || {}).date1904;
    const sheetList = wb.SheetNames;
    let data = [] as IExcelDataArray;
    let headerError = '';
    for (let i = 0; i < sheetList.length; i++) {
      const sheetName = sheetList[i];
      let tabIdx = templateTabs[sheetName];
      if (tabIdx !== undefined) {
        const ws = wb.Sheets[sheetName];
        const { ok, extraHeaders } = verifyTemplateHeader(ws, sheetName);
        if (ok) {
          data[tabIdx] = {
            sheetName: sheetName,
            sheetData: XLSX.utils.sheet_to_json(ws, {
              // header: 1,
              blankrows: false,
              raw: true,
              dateNF: 22
            }),
            extraHeaders,
            date1904
          };
          // console.log(JSON.stringify(data[tabIdx].sheetData));
        } else {
          headerError += sheetName + ' ';
        }
      }
    }
    headerError = headerError.trim();
    if (headerError) {
      setErrorMessage('Invalid headers for ' + headerError.replace(' ', ' , '));
    } else {
      if (!_.isEmpty(data)) {
        setErrorMessage('');
        data = cleanData(data);
        // console.log(JSON.stringify(wb));
      } else {
        setErrorMessage(
          'No valid data found. Please use the template provided.'
        );
      }
      setFileData(data);
    }
  };

  const handleProgress = useCallback(
    (step: number, percent: number, base?: 'e' | 'u') => {
      var pct = percent;
      if (step === -1) {
        console.log('handleProgress HIDE_STEP ');
        // prettier-ignore
        dispatch({type: HIDE_STEP, payload: {}});
      } else {
        if (!Number.isNaN(pct)) {
          if (step === 0 && base) {
            if (base === 'e') {
              console.log('handleProgress step 0 setting export base ');
              // prettier-ignore
              dispatch({type: SET_EXPORT_BASE, payload: {}});
            } else {
              console.log('handleProgress step 0 setting update base ');
              // prettier-ignore
              dispatch({type: SET_UPDATE_BASE, payload: {}});
            }
          } else {
            console.log(
              'handleProgress percent: ' + percent + ' step: ' + step
            );
            // prettier-ignore
            dispatch({type: UPDATE_INDEX_STEP, payload: { percent: pct, currentStepIndex: step }});
          }
        }
      }
    },
    [dispatch]
  );

  return (
    <div className="wrapper">
      <h1>Bulk Edit</h1>
      <div className="bulk-edit-page d-flex">
        <div className="left-content">
          <div className="upload-section mb-30">
            <span>File Upload</span>
            <label htmlFor="myfile" className={`${start ? 'disabled' : ''}`}>
              Choose File
            </label>
            <input
              type="file"
              id="myfile"
              name="myfile"
              accept=".xlsx, .xls"
              onChange={handleChangeFile}
              hidden
              disabled={start}
            />
            <span id="file-chosen">
              {_.isEmpty(fileName) ? 'No file chosen' : fileName}
            </span>
            <div className="error">{errorMessage}</div>
          </div>
          <div className="process-upload mb-30">
            <Button
              type="button"
              data-testid="btn-process-upload"
              displayType="secondary"
              onClick={handleUpload}
              disabled={!_.isEmpty(errorMessage) || start || !fileName}
            >
              Process Upload
            </Button>
            <a
              href="./Package_Campaign_Cloning_Template.xlsx"
              download
              target="_blank"
              rel="noopener noreferrer"
              className={`${start ? 'download-disabled' : ''}`}
            >
              <Button
                type="button"
                data-testid="btn-download-template"
                displayType="secondary"
                disabled={start}
                onClick={() => {}}
              >
                Download Template
              </Button>
            </a>
            <Button
              type="button"
              data-testid="btn-bulk-edit1"
              displayType="secondary"
              disabled={start}
              onClick={() => {
                exportPackageCampaigns(setStart, handleProgress);
              }}
            >
              Export Packages and Campaigns
            </Button>
            {/* {start && (
              <div style={{ width: '100%', position: 'relative', top: '10px' }}>
                <ProgressBar
                  labelSize="12px"
                  bgcolor="#2eaeff"
                  borderRadius="5px"
                  completed={completed}
                />
              </div>
            )}progressStep.display || */}
            {progressStep.display && (
              <div>
                <div style={{ height: '100px' }}></div>
                <div
                  style={{
                    height: '100px',
                    width:
                      '180%' /* 180% stretches the bar enough to make everything readable*/
                  }}
                >
                  <SteppedProgressBar
                    isVertical={false}
                    activeStepColor={1}
                    completedStepColor={6}
                    {...progressStep}
                  />
                </div>
              </div>
            )}
          </div>
          {!start && completed === 100 && (
            <DownloadReport statusReport={statusReport} />
          )}
        </div>
        <div className="right-content">
          <Link to={homePageUrl}>
            <Button
              type="button"
              data-testid="btn-done"
              displayType="secondary"
              disabled={start}
              // onClick={() => history.push(`/`)}
            >
              Done
            </Button>
          </Link>
          <a
            href="./Package_Campaign_Bulk_Update_Manual.pdf"
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type="button"
              data-testid="btn-user-manual"
              displayType="secondary"
              disabled={start}
              onClick={() => {}}
            >
              User Manual
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BulkEdit;
