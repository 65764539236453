import { IRunBaseId } from './LoadData';
import { readQuery$ } from './readQuery';

interface IRunBaseRead extends IRunBaseId {
  BaseIdName?: string;
  BaseId: string;
}

/**
 * read campaigns + filter relevant properties
 * @param props
 */
export const readCampaign$ = (props: IRunBaseRead) => {
  var BaseIdName = 'AdvertiserId';
  return readQuery$({
    ...props,
    BaseIdName,
    method: 'POST',
    shortUrl: '/campaign/query/advertiser',
    responseFilterFieldList: [
      'CampaignName',
      'CampaignId',
      'AdvertiserId',
      'StartDate',
      'EndDate',
      'Budget',
      'DailyBudget',
      'BudgetInImpressions',
      'DailyBudgetInImpressions',
      'PacingMode',
      'TimeZone',
      'CampaignFlights',
      // perhaps only needed here
      'CreatedAtUTC',
      'LastUpdatedAtUTC',
      'CampaignConversionReportingColumns',
      'AutoAllocatorEnabled',
      'AutoPrioritizationEnabled',
      'Objective',
      'PrimaryGoal',
      'PrimaryChannel'
    ],
    errorId: BaseIdName
  });
};
