import { getAdvertiserAdgroups, getCampaigns } from './ttdapi';

/**
 * calculate combined progress for 2 api requests
 * @param funcCallback - callback function for reporting progress
 * @returns {f1, f2} individual progress functions
 */
function part2Progress(funcCallback: Function | undefined) {
  if (!(typeof funcCallback === 'function')) {
    return { f1: undefined, f2: undefined };
  }
  var part1 = 0,
    part2 = 0;
  const adjustProgress = () => {
    console.log('part1: ' + part1 + ' part2: ' + part2);
    var pct = (part1 + part2) / 2;
    if (typeof funcCallback === 'function') funcCallback({ percent: pct });
  };
  const partPct = (percent: number | Object) => {
    if (typeof percent === 'object') {
      //@ts-ignore
      return percent.percent;
    } else {
      return percent;
    }
  };
  const update1 = (percent: number | Object) => {
    part1 = partPct(percent);
    adjustProgress();
  };
  const update2 = (percent: number | Object) => {
    part2 = partPct(percent);
    adjustProgress();
  };
  return { f1: update1, f2: update2 };
}

/**
 * filters out no longer running campaigns
 * @param campArr - array of campaigns
 * @returns
 */
function filterRunningCampaigns(campArr) {
  var dateMin = new Date(
    new Date(new Date().getTime()).toISOString().replace(/T.*/, '')
  );
  var liveCamps = campArr.filter((el) => {
    var strEndDt = el.EndDate;
    var dateComp = new Date(
      new Date(strEndDt).toISOString().replace(/T.*/, '')
    );
    return !(strEndDt !== null && dateComp < dateMin);
  });
  return liveCamps;
}

/**
 * gets indexed live campaigns for a set of advertiser ids
 * @param token - Authentication token
 * @param arrAdv - array of advertiser ids
 * @param updateProgress - optional function to handle progress updates
 * @returns { CampaignId:
 *   { CampaignName, CampaignId, AdvertiserId, StartDate, EndDate, Budget,
 *                 DailyBudget, BudgetInImpressions, DailyBudgetInImpressions,
 *                 PacingMode, TimeZone, CampaignFlights,
 *                 CampaignConversionReportingColumns }
 * }
 */
export const advertiserLiveCampaigns = async (
  token,
  arrAdv,
  updateProgress: Function | undefined = undefined
) => {
  var campIdx,
    liveAgCamp = []; // will hold campaign array
  if (!Array.isArray(arrAdv) || arrAdv.length === 0) {
    return [];
  }
  var { f1, f2 } = part2Progress(updateProgress);
  var campPromiseArr = [
    getCampaigns(token, arrAdv, f1),
    getAdvertiserAdgroups(token, arrAdv, f2)
  ];
  var results = await Promise.all(campPromiseArr);
  var campResp = results[0],
    agRes = results[1],
    campData = campResp.data,
    agData = agRes.data;
  campData = filterRunningCampaigns(campData);
  if (campData.length > 0 && agData.length > 0) {
    campIdx = campData.reduce((acc, el) => {
      acc[el.CampaignId] = el;
      return acc;
    }, {});
    liveAgCamp = agData.reduce((acc, agEl) => {
      var cId = agEl['CampaignId'];
      if (campIdx[cId] && !acc[cId]) {
        // campaign not marked as live yet
        if (agEl.IsEnabled) {
          acc[cId] = campIdx[cId];
        }
      }
      return acc;
    }, {});
  }
  return liveAgCamp;
};
